<ng-container
  *ngIf="_globalService.website?.id"
>

  <div style="transition: 0.5s" [style.opacity]="isServer ? 0 : 1">

    <app-header 
      id="header"
      *ngIf="_globalService.page != 'purchase-steps'"
    >
    </app-header>

    <main [class.of-hidden]="_globalService.page == 'home'">
      <router-outlet></router-outlet>
    </main>

    <app-footer
      id="footer"
      *ngIf="_globalService.page != 'purchase-steps'"
    >
    </app-footer>
  </div>


</ng-container>

import {
  Component,
  PLATFORM_ID,
  Inject,
  Optional,
  HostListener,
  ViewEncapsulation,
  ViewContainerRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";

// services
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { GlobalService } from "./core/services/global.service";
import { UserService } from "./core/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls:
    environment.production ? 
      ["./app.component.scss"]:
      ["./app.component.scss",`../styles/${environment.template_id}/styles.scss`],
  encapsulation: 
    environment.production ? 
      ViewEncapsulation.Emulated: 
      ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit{

  loginBox: boolean;
  heightHeader: number;
  heightHeaderBase: number;
  domReady: boolean;

  isBrowser: boolean;
  isServer: boolean;

  constructor(
    private vcr: ViewContainerRef,
    public _globalService: GlobalService,
    private _userService: UserService,
    @Inject(DOCUMENT) private doc: Document,
    @Optional() @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject("hostname") private hostname: string,
    @Optional() @Inject("draft") private draft_id: string,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isServer = isPlatformServer(this.platformId);
  }

  ngOnInit(){

    try {
     
      const website = this._globalService.getState("websiteInfo", undefined);
      const style = this._globalService.getState("styleInfo", undefined);
      const styleProduct = this._globalService.getState("styleProductInfo", undefined);
      const options = this._globalService.getState("optionsInfo", undefined);
      const social = this._globalService.getState("socialInfo", undefined);
      const header = this._globalService.getState("headerInfo", undefined);
      const menu = this._globalService.getState("menuInfo", undefined);
      const installments = this._globalService.getState("installmentsInfo", undefined);
      const footer = this._globalService.getState("footerInfo", undefined);

      if (!style || !website || !social || !options || !header || !menu || !footer) {

        if (!this.hostname)
          this.hostname = environment.hostname;

        if (this.hostname) {

          this._globalService.getWebsiteInfo(this.hostname, this.draft_id).subscribe(
            (res: any) => {
              if (res.status === 200) {

                let { 
                  general: dGeneral, 
                  product: dProduct, 
                  header: dHeader,
                  footer,
                  menu,
                  installments = 1
                } = res.data;

                const style = {

                  color_primary: dGeneral.color_primary,
                  color_contrast_primary: dGeneral.color_contrast_primary,

                  color_secondary: dGeneral.color_secondary,
                  color_contrast_secondary: dGeneral.color_contrast_secondary,

                  color_text_primary: dGeneral.color_text_primary,
                  color_background: dGeneral.color_background,

                  color_header_bg: dGeneral.color_header_bg || "var(--color_primary)",
                  color_header_text: dGeneral.color_header_text || "var(--color_contrast_primary)",

                  color_header_message_bg: dGeneral.color_header_message_bg || "var(--color_contrast_secondary)",
                  color_header_message_text: dGeneral.color_header_message_text || "var(--color_secondary)",
                  
                  color_header_second_message_bg: dGeneral.color_header_second_message_bg || "var(--color_contrast_secondary)",
                  color_header_second_message_text: dGeneral.color_header_second_message_text || "var(--color_secondary)",

                  color_footer_bg: dGeneral.color_footer_bg || "var(--color_primary)",
                  color_footer_text: dGeneral.color_footer_text || "var(--color_contrast_primary)",


                };

                const styleProduct = {
                  aspect_ratio: dProduct.aspect_ratio,
                  show_sku: dProduct.show_sku,
                  show_buy_now: dProduct.show_buy_now,
                  show_installments: dProduct.show_installments,
                  show_methods_payments: dProduct.show_methods_payments,
                  text_align: dProduct.text_align,
                  show_border: dProduct.show_border,
                  object_fit: dProduct.object_fit,
                  type_product_subtext_id: dProduct.type_product_subtext_id,
                  subtext_custom: dProduct.subtext_custom,
                  type_product_text_price_id: dProduct.type_product_text_price_id
                }

                const website = {
                  id: dGeneral.id,
                  title: dGeneral.title,
                  description:
                    dGeneral.seo_description ||
                    `Esta es la tienda online de ${dGeneral.title}! Ingresá y conocé nuestra amplia variedad de productos.`,
                  direction: dGeneral.direction,
                  email: dGeneral.email,
                  logo: this._globalService.galleryUrl + dGeneral.logo_url,
                  favicon:
                    dGeneral.favicon_url ?
                      this._globalService.galleryUrl + dGeneral.favicon_url:
                      this._globalService.galleryUrl + dGeneral.logo_url,
                  phone: dGeneral.phone,
                  font_style: {
                    primary: {
                      css_style: dGeneral.font_primary_css_title,
                      url: dGeneral.font_primary_url,
                    },
                    secondary: {
                      css_style: dGeneral.font_secondary_css_title,
                      url: dGeneral.font_secondary_url,
                    },
                  },
                  url: dGeneral.domain || this.hostname,
                  currency: dGeneral.currency,
                  currency_title: dGeneral.currency_title,
                  decimals: dGeneral.decimals,
                  country_id: dGeneral.country_id,
                  whatsapp_checkout_mode: dGeneral.whatsapp_checkout_mode,
                  whatsapp_checkout_pro: dGeneral.whatsapp_checkout_pro
                };

                const header = {

                  base_visible_message: dHeader.visible_message,
                  visible_message: dHeader.visible_message,
                  message_text: dHeader.message_text,
                  message_link: dHeader.message_link,
                  type_message_id: dHeader.type_message_id,
                  message_position_top: dHeader.message_position_top,
                  message_time: dHeader.message_time,

                  second_base_visible_message: dHeader.second_visible_message,
                  second_visible_message: dHeader.second_visible_message,
                  second_message_text: dHeader.second_message_text,
                  second_message_link: dHeader.second_message_link,
                  second_type_message_id: dHeader.second_type_message_id,
                  second_message_position_top: dHeader.second_message_position_top,

                  type_background_header_id: dHeader.type_background_header_id,
                  base_type_background_header_id: dHeader.type_background_header_id,
                  is_fixed: dHeader.is_fixed,
                  visible_searchbar: dHeader.visible_searchbar,
                  visible_session: dHeader.visible_session,
                  above_carrousel: dHeader.above_carrousel,
                  transparent_carrousel: dHeader.transparent_carrousel,
                  logo_size: dHeader.logo_size
                };


                const options = {
                  chat: {
                    status: dGeneral.chat,
                    data: {
                      chat_facebook_url: dGeneral.chat_facebook_url,
                      chat_whatsapp_url: dGeneral.chat_whatsapp_url,
                      chat_instagram_url: dGeneral.chat_instagram_url
                    },
                  },
                  datafiscal: {
                    status: dGeneral.datafiscal,
                    data: {
                      datafiscal_codigo: dGeneral.datafiscal_codigo,
                    },
                  },
                  form_contact: {
                    status: dGeneral.form_contact,
                    data: {},
                  },
                  blog: {
                    status: dGeneral.blog,
                    data: [],
                  },
                  google_analytics: {
                    status: dGeneral.google_analytics,
                    data: dGeneral.google_analytics_code,
                  },
                  facebook_pixel: {
                    status: dGeneral.facebook_pixel,
                    data: dGeneral.facebook_pixel_code,
                  },
                  shipment_alert: {
                    status: dGeneral.shipment_alert,
                    data: dGeneral.shipment_alert_text,
                  },
                  checkout: {
                    only_buy_client:
                      dGeneral.only_buy_client,
                    only_show_price_client:
                      dGeneral.only_show_price_client,
                    price_min_buy:
                      dGeneral.price_min_buy,
                    required_dni:
                      dGeneral.checkout_required_dni,
                    required_phone:
                      dGeneral.checkout_required_phone,
                    required_note:
                      dGeneral.checkout_required_note,
                    required_company:
                      dGeneral.checkout_required_company,
                     required_domicile_payment:
                      dGeneral.checkout_required_domicile_payment
                  },
                  scripts: {
                    status: (dGeneral.script_head || dGeneral.script_body) ? 1 : 0,
                    data: {
                      head: dGeneral.script_head,
                      body: dGeneral.script_body
                    },
                  },
                  wholesale: {
                    status: dGeneral.wholesale_mode
                  }
                };

                const social = {
                  twitter: dGeneral.twitter,
                  facebook: dGeneral.facebook,
                  pinterest: dGeneral.pinterest,
                  instagram: dGeneral.instagram,
                  youtube: dGeneral.youtube,
                  tiktok: dGeneral.tiktok,
                };

                if (dGeneral.only_show_price_client) {
                  this._globalService.showPrices = false;
                }

                //this.setStyles(style);
                this._globalService.setWebsiteInfo(website);
                this._globalService.addMetatags();

                this._globalService.setState("socialInfo", social);
                this._globalService.setState("optionsInfo", options);
                this._globalService.setState("websiteInfo", website);
                this._globalService.setState("styleInfo", style);
                this._globalService.setState("styleProductInfo", styleProduct);
                this._globalService.setState("headerInfo", header);
                this._globalService.setState("footerInfo", footer);
                this._globalService.setState("menuInfo", menu);
                this._globalService.setState("installmentsInfo", installments);

                this._globalService.social = social;
                this._globalService.options = options;
                this._globalService.header = header;
                this._globalService.menu = menu;
                this._globalService.installments = installments;
                this._globalService.footer = footer;

                this._globalService.updateCSSExternal(
                  dGeneral.css_external
                );

                this._globalService.setStyles(style);
                this._globalService.setStyleProduct(styleProduct);
                this._globalService.setFavicon(website.favicon);
                this._globalService.setFontStyle(website.font_style);
                this._globalService.onUpdateHeader.emit();

                //Inserto los elementos
                this.importModules();

                // Valido session
                this.checkSession();

              }
            },
            (error) => {},
            () => {}
          );
        }

      } else {

        this._globalService.website = website;
        this._globalService.styles = style;
        this._globalService.styleProduct = styleProduct;
        this._globalService.social = social;
        this._globalService.options = options;
        this._globalService.header = header;
        this._globalService.menu = menu;
        this._globalService.installments = installments;
        this._globalService.footer = footer;
        this._globalService.updateCSSExternal();

        if (options.checkout.only_show_price_client) {
          this._globalService.showPrices = false;
        }

        if (this.isBrowser) {
          this._globalService.setStyles(style);
          this._globalService.setStyleProduct(styleProduct);
          this._globalService.setFavicon(website.favicon);
          this._globalService.setFontStyle(website.font_style);
          this._globalService.onUpdateHeader.emit();
        }

        this.importModules();
        this.checkSession();

      }


    } catch (error) {
      console.log("Error", error);
    }

  }

  ngAfterViewInit(): void {

    if(this.isBrowser){

      document.body.style.overflow = 'auto';
      //SCROLL APPLY
      //console.log("Tamaño del body en after view init:", document.body.clientHeight);
      //const scroll = document.body.getAttribute('data-scroll') || 0;
      //console.log(`Scroll first:`, this._viewportScroller.getScrollPosition());
      //console.log(`Scroll actual:`, scroll);
      //
      // if(scroll){
      //   console.log("Scroll a la siguiente posicion:", scroll);
      //   setTimeout(()=>{
      //      window.scrollTo(0, +scroll);
      //     console.log(document.body.clientHeight);
      //   }, 0)
      // }

      //SCROLL END

    }

  }

  //Recibir los datos, para editarlo al antojo como hace el backoffice
  //Aqui recibire los datos, vamos a ver como va.
  @HostListener("window:message", ["$event"])
  onMessage(e) {

    if (
      e && e.data
    ) {

      if (e.data.commerce) {

        console.log("Nueva informacion del padre creador:", e.data);

        const style = {
          color_contrast_primary: e.data.commerce.color_contrast_primary,
          color_contrast_secondary: e.data.commerce.color_contrast_secondary,
          color_primary: e.data.commerce.color_primary,
          color_secondary: e.data.commerce.color_secondary,
          color_text_primary: e.data.commerce.color_text_primary,
          color_background: e.data.commerce.color_background,

          color_header_bg: e.data.commerce.color_header_bg,
          color_header_text: e.data.commerce.color_header_text,

          color_header_message_bg: e.data.commerce.color_header_message_bg,
          color_header_message_text: e.data.commerce.color_header_message_text,
          color_header_second_message_bg: e.data.commerce.color_header_second_message_bg,
          color_header_second_message_text: e.data.commerce.color_header_second_message_text,

          color_footer_bg: e.data.commerce.color_footer_bg,
          color_footer_text: e.data.commerce.color_footer_text,
        };

        const styleProduct = {
          aspect_ratio: e.data.commerce.product.aspect_ratio,
          show_sku: e.data.commerce.product.show_sku,
          show_buy_now: e.data.commerce.product.show_buy_now,
          show_installments: e.data.commerce.product.show_installments,
          show_methods_payments: e.data.commerce.product.show_methods_payments,
          text_align:  e.data.commerce.product.text_align,
          show_border: e.data.commerce.product.show_border,
          object_fit:  e.data.commerce.product.object_fit,
          type_product_subtext_id: e.data.commerce.product.type_product_subtext_id,
          subtext_custom: e.data.commerce.product.subtext_custom,
          type_product_text_price_id: e.data.commerce.product.type_product_text_price_id
        }

        const website = {
          id: e.data.commerce.id,
          title: e.data.commerce.title,
          description:
            e.data.commerce.seo_description ||
            `Esta es la tienda online de ${e.data.commerce.title}! Ingresá y conocé nuestra amplia variedad de productos.`,
          direction: e.data.commerce.direction,
          email: e.data.commerce.email,
          logo: e.data.commerce.logo_url
            ? this._globalService.galleryUrl + e.data.commerce.logo_url
            : e.data.commerce.newLogo,
          phone: e.data.commerce.phone,
          currency: this._globalService.website.currency,
          currency_title: this._globalService.website.currency_title,
          decimals: this._globalService.website.decimals,
          currency_id: this._globalService.website.currency_id,
        };

        const header = {

          // ---
          base_visible_message: e.data.commerce.header.visible_message,
          visible_message: e.data.commerce.header.visible_message,
          message_text: e.data.commerce.header.message_text,
          message_link: e.data.commerce.header.message_link,
          type_message_id: e.data.commerce.header.type_message_id,
          message_position_top: e.data.commerce.header.message_position_top,
          message_time: e.data.commerce.header.message_time,

          // ---
          second_base_visible_message: e.data.commerce.header.second_visible_message,
          second_visible_message: e.data.commerce.header.second_visible_message,
          second_message_text: e.data.commerce.header.second_message_text,
          second_message_link: e.data.commerce.header.second_message_link,
          second_type_message_id: e.data.commerce.header.second_type_message_id,
          second_message_position_top: e.data.commerce.header.second_message_position_top,
          // ---

          type_background_header_id: e.data.commerce.header.type_background_header_id,
          base_type_background_header_id: e.data.commerce.header.type_background_header_id,
          is_fixed: e.data.commerce.header.is_fixed,
          visible_searchbar: e.data.commerce.header.visible_searchbar,
          visible_session: e.data.commerce.header.visible_session,
          above_carrousel: e.data.commerce.header.above_carrousel,
          transparent_carrousel: e.data.commerce.header.transparent_carrousel,
          logo_size: e.data.commerce.header.logo_size,

        };

        //Reviso si activare el Above Carrousel, si la primera seccion es un carrusel
        if (
          e.data.sections &&
          e.data.sections.length &&
          e.data.sections[0].type_section_id != 2
        ) {
          header.above_carrousel = false;
        }

        //Reviso si hay una fuente nueva para insertarla
        if (e.data.commerce.font_primary && e.data.commerce.font_secondary) {
          website["font_style"] = {
            primary: {
              css_style: e.data.commerce.font_primary.css_title || null,
              url: e.data.commerce.font_primary.url || null,
            },
            secondary: {
              css_style: e.data.commerce.font_secondary.css_title || null,
              url: e.data.commerce.font_secondary.url || null,
            },
          };

          this._globalService.setFontStyle(website["font_style"]);
        }

        this._globalService.website = website;
        this._globalService.setStyles(style);
        this._globalService.setStyleProduct(styleProduct);
        this._globalService.setHeader(header);
        this._globalService.updateCSSExternal(e.data.commerce.css_external);
      }

      if (e.data.sections) {
        //this._globalService.sections = e.data.sections;
        let i = 0;
        if(!this._globalService.sections){
          this._globalService.sections = [];
        }

        while(e.data.sections.length < this._globalService.sections.length){
          this._globalService.sections.pop();
        }

        for(let section of e.data.sections){

          if(this._globalService.sections[i]){
            if(this._globalService.sections[i].id === section.id){
              this._globalService.sections[i].config = section.config;
            }else{
              this._globalService.sections[i] = section;
            }
          }else{
            this._globalService.sections.push(section);
          }

          i++;

        }

        this._globalService.onUpdateSection.emit(e.data.focus_section_id);

        /**
        if(e.data.focus_section_id){
          let section = document.getElementById(`section-${e.data.focus_section_id}`);
          if(section){
            const y = section.getBoundingClientRect().top + window.pageYOffset + (window.innerHeight >= window.innerWidth ? -75:-150);
            window.scrollTo({top: y, behavior: 'smooth'});
          }
        }
        */

      }

      if(e.data.commerce || e.data.sections){
        console.log("Is backoffice", e);
        this._globalService.isBackoffice = true;
        e.source.postMessage({ success: "true" }, e.origin);
      }

      if(e.data.focus_element_id){

        let id = e.data.focus_element_id;

        setTimeout(()=>{
          
          const section = document.getElementById(id);

          if(id){
            const y = section.getBoundingClientRect().top + window.pageYOffset + (window.innerHeight >= window.innerWidth ? -75:-150);
            window.scrollTo({top: y, behavior: 'smooth'});
          }
          
        }, 500);

      }

    }

  }

  checkSession(){

    const token = this._globalService.getStorage("auth_token"),
          profile = this._globalService.getStorage("profile"),
          removeAuth = () => {
            this._globalService.isLogged = false;
            this._globalService.profile = null;
            this._globalService.removeStorage("profile");
            this._globalService.removeStorage("auth_token");
          };

      if (token) {

        if (profile) {
          this._globalService.profile = JSON.parse(profile);
          this._globalService.isLogged = true;
        } else {
          this._globalService.isLogged = false;
        }

        this._userService.ping().subscribe(
          (response: any) => {
            if (response.status == 1) {
              const token = response.data[0].token;
              this._globalService.setStorage("auth_token", token);
              this._globalService.showPrices = true;
            } else removeAuth();
          },
          () => removeAuth()
        );
      }

      // open login box when is required
      this._globalService.signinRequired.subscribe(() => {
        if (!this._globalService.isLogged)
          this.loginBox = this.loginBox ? false : true;
      });

  }


  changeHeight($event) {
    if (this._globalService.isBrowser) {
      this.heightHeaderBase = $event;
      this.heightHeader = this.heightHeaderBase;
    }
  }

  importModules(){

    //Importacion dinamicas de componentes
    if(this.isBrowser){

      if(this._globalService.options.google_analytics.status){
        import ('./core/components/google-analytics-gtag/google-analytics-gtag.component').then((m)=>{
          this.vcr.createComponent(m.GoogleAnalyticsGTagComponent);
        })
      }

      if(this._globalService.options.facebook_pixel.status){
        import ('./core/components/facebook-pixel/facebook-pixel.component').then((m)=>{
          this.vcr.createComponent(m.FacebookPixelComponent);
        })
      }

      //Importo el resto de los componentes
      import('./core/components/regret-box/regret-box.component').then((m)=>{
        this.vcr.createComponent(m.RegretBoxComponent);
      });

      import('./core/components/login-box/login-box.component').then((m)=>{
        this.vcr.createComponent(m.LoginBoxComponent);
      });

      import('./core/components/chat-button/chat-button.component').then((m)=>{
        this.vcr.createComponent(m.ChatButtonComponent);
      });
      //Fin de la importacion de los componentes;

    // Importacion del server
    }else{
            
      if(this._globalService.options.scripts.status){
        import ('./core/components/scripts-external/scripts-external.component').then((m)=>{
          this.vcr.createComponent(m.ScriptsExternalComponent);
        })
      }

    }


  }

}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

// services
import { GlobalService } from './global.service'
import { map } from 'rxjs';
import { AnalyticsService } from './analytics.service';


@Injectable({
  providedIn: 'root'
})

export class WebsiteService {

  url: string;

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService,
    private _analyticsService: AnalyticsService
  ) { 
    this.url = `${ this._globalService.apiUrl }website`
  }


  getFaq() {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/faq`, options)
  }


  getLocation(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)

    return this._http.post(`${ this.url }/location`, form, options)
  }


  setContactRequest(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.post(`${ this.url }/contact`, form, options)
  }

  setRegretRequest(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.post(`${ this.url }/regret`, form, options)
  }

  setLandingRequest(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.post(`${ this.url }/landing`, form, options)
  }

  setNewsletterRequest(form) {
    
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)

    return this._http.post(`${ this.url }/newsletter`, form, options).pipe(map((res: any)=>{

      if(res.status == 201){
        const { email, event_id } = res.data;
        this._analyticsService.trackEvent({
          data: { email },
          event: 'newsletter_subscribe',
          event_id
        });
      }

      return res;

    }))
  }

  getBanners(){
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/banners`, options);
  }

}

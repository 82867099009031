<svg width="2241.3" height="595.37" version="1.1" viewBox="0 0 2241.3 595.37" xml:space="preserve"
  xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
  xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
  <defs>
    <clipPath id="clipPath50">
      <path
        d="m3369.2 40.07h-2273c-583.28 0-1056.1 472.84-1056.1 1056.1v2273c0 583.28 472.84 1056.1 1056.1 1056.1h2273c583.28 0 1056.1-472.85 1056.1-1056.1v-2273c0-583.28-472.84-1056.1-1056.1-1056.1z" />
    </clipPath>
  </defs>
  <g transform="matrix(1.3333 0 0 -1.3333 0 595.37)">
    <g transform="scale(.1)">
      <g fill="var(--color_footer_text)">
        <path d="m5693.9 3742.7h-482.58v430.79h1520.7v-430.79h-482.59v-1217.1h-555.56v1217.1" />
        <path d="m6875.7 4173.5h555.57v-1647.9h-555.57v1647.9" />
        <path
          d="m9069.7 2944.7v-419.03h-1370.1v1647.9h1339.5v-419.03h-793.33v-193.02h696.81v-400.2h-696.81v-216.58h823.93" />
        <path
          d="m10908 4173.5v-1647.9h-456.7l-626.17 748.61v-748.61h-541.44v1647.9h456.69l626.22-748.59v748.59h541.4" />
        <path
          d="m11965 2958.8c233.1 0 393.2 138.89 393.2 390.78 0 251.88-160.1 390.77-393.2 390.77h-233v-781.55zm-788.6 1214.7h812.2c550.8 0 929.8-313.09 929.8-823.92 0-510.85-379-823.94-929.8-823.94h-812.2v1647.9" />
        <path
          d="m14068 3213.1-160.1 414.32-160.1-414.32zm153-400.19h-626.2l-110.6-287.21h-565l720.3 1647.9h546.2l720.3-1647.9h-574.4l-110.6 287.21" />
        <path d="m6781.4 1915.3v-1647.9h-456.69l-626.18 748.6v-748.6h-541.44v1647.9h456.68l626.19-748.6v748.6h541.44" />
        <path
          d="m8419.9 686.47v-419.03h-1370.1v1647.9h1339.5v-419.04h-793.33v-193.01h696.81v-400.2h-696.81v-216.58h823.93" />
        <path
          d="m9724.1 1136.1h482.58v-696.79c-200.1-136.54-475.52-209.52-717.99-209.52-532.02 0-920.45 355.46-920.45 861.59 0 506.13 388.43 861.58 932.22 861.58 324.87 0 586.12-112.99 753.32-317.79l-355.48-313.1c-103.59 120.07-223.64 181.27-369.59 181.27-244.83 0-400.2-157.72-400.2-411.96 0-247.18 155.37-411.96 393.14-411.96 68.27 0 134.18 11.762 202.45 40.02v416.66" />
        <path
          d="m11678 1091.4c0 256.6-164.8 411.96-369.6 411.96s-369.6-155.36-369.6-411.96 164.8-411.96 369.6-411.96 369.6 155.36 369.6 411.96zm-1299.5 0c0 499.06 393.1 861.58 929.9 861.58 536.7 0 929.8-362.52 929.8-861.58 0-499.06-393.1-861.59-929.8-861.59-536.8 0-929.9 362.53-929.9 861.59" />
        <path
          d="m12375 1091.4c0 506.13 388.5 861.58 918.1 861.58 322.5 0 576.8-117.68 739.2-329.57l-350.8-313.08c-96.5 122.41-211.8 193.03-360.1 193.03-230.7 0-386.1-160.07-386.1-411.96 0-251.87 155.4-411.96 386.1-411.96 148.3 0 263.6 70.621 360.1 193.03l350.8-313.09c-162.4-211.88-416.7-329.57-739.2-329.57-529.6 0-918.1 355.46-918.1 861.59" />
        <path d="m14192 1915.3h555.6v-1647.9h-555.6v1647.9" />
      </g>
      <g transform="scale(1.0313)">
        <path
          d="m15757 1058.3c0 248.81-159.8 399.46-358.4 399.46s-358.4-150.65-358.4-399.46c0-248.82 159.8-399.46 358.4-399.46s358.4 150.65 358.4 399.46zm-1260.1 0c0 483.92 381.3 835.44 901.7 835.44s901.7-351.52 901.7-835.44c0-483.92-381.3-835.45-901.7-835.45s-901.7 351.53-901.7 835.45"
          fill="var(--color_footer_text)" />
      </g>
      <path
        d="m3369.2 40.07h-2273c-583.28 0-1056.1 472.84-1056.1 1056.1v2273c0 583.28 472.84 1056.1 1056.1 1056.1h2273c583.28 0 1056.1-472.85 1056.1-1056.1v-2273c0-583.28-472.84-1056.1-1056.1-1056.1"
        fill="var(--color_footer_text)" />
      <g clip-path="url(#clipPath50)">
        <path
          d="m3536.2 516.37c-15.03 0-29.85-0.691-44.45-2.223-83.29 193.01-275.22 328.1-498.72 328.1-237.41 0-438.67-152.51-512.54-364.77-53.53 91.2-151.62 280.72-151.62 394.16h-185.38c0-113.44-105.11-302.91-158.68-394.16-73.87 212.26-275.13 364.77-512.54 364.77-223.55 0-415.39-135.13-498.68-328.1-14.644 1.532-29.461 2.223-44.453 2.223-239.93 0-434.5-194.48-434.5-434.46h3476c0 239.98-194.53 434.46-434.46 434.46"
          fill="var(--color_footer_bg)" />
      </g>
      <path
        d="m3369.2 40.07h-2273c-583.28 0-1056.1 472.84-1056.1 1056.1v2273c0 583.28 472.84 1056.1 1056.1 1056.1h2273c583.28 0 1056.1-472.85 1056.1-1056.1v-2273c0-583.28-472.84-1056.1-1056.1-1056.1z"
        fill="none" stroke="var(--color_footer_text)" stroke-miterlimit="10" stroke-width="80.13" />
      <path
        d="m2275.4 1169-44.17-53-45.01 52.29c-93.6 108.76-145.58 247.19-146.72 390.68l376.42 2.98c1.13-143.49-48.66-282.73-140.52-392.95"
        fill="var(--color_footer_bg)" />
      <path
        d="m2282 1286.3-53.11 124.05-51.13-124.88-67.31 59.33-20.12-28.2c24.03-54.93 56.21-106.42 95.93-152.56l45-52.29 44.17 53c38.97 46.76 70.34 98.75 93.48 154.06l-20.56 27.87-66.35-60.38"
        fill="var(--color_footer_bg)" />
      <path
        d="m2282 1286.3-53.11 124.05-51.13-124.88-67.31 59.33-20.12-28.2c24.03-54.93 56.21-106.42 95.93-152.56l45-52.29 44.17 53c38.97 46.76 70.34 98.75 93.48 154.06l-20.56 27.87-66.35-60.38z"
        fill="none" stroke="var(--color_footer_text)" stroke-miterlimit="10" stroke-width="26.71" />
      <path
        d="m1990.6 1686-295.02-383.7-233.06 638.37c-34.86 95.49-13.87 202.43 54.5 277.65l203.47 220.7 274.3-745.1-4.19-7.92"
        fill="var(--color_footer_bg)" />
      <path d="m1990.6 1686-295.02-383.7-233.06 638.37c-34.86 95.49-13.87 202.43 54.5 277.65l203.47 220.7 274.3-745.1z"
        fill="none" stroke="var(--color_footer_bg)" stroke-miterlimit="10" stroke-width="50.986" />
      <path
        d="m2457.8 1686 295.03-383.7 233.06 638.37c34.85 95.49 13.86 202.43-54.51 277.65l-203.46 220.7-274.31-745.1 4.19-7.92"
        fill="var(--color_footer_bg)" />
      <path
        d="m2457.8 1686 295.03-383.7 233.06 638.37c34.85 95.49 13.86 202.43-54.51 277.65l-203.46 220.7-274.31-745.1z"
        fill="none" stroke="var(--color_footer_bg)" stroke-miterlimit="10" stroke-width="50.986" />
      <path
        d="m2429.8 3035.6c1.1-112.37-89.02-204.27-201.4-205.38-112.37-1.1-204.55 89.01-205.65 201.39-1.1 112.37 89.28 204.56 201.66 205.66 112.37 1.1 204.28-89.29 205.39-201.67zm-212.86 966.66s-90.16-130.08-193.16-347.28l393.11 3.86c-107.25 215.14-199.95 343.42-199.95 343.42zm12.18-1240c-149.91-1.47-272.89 118.74-274.36 268.65-1.47 149.92 119.11 272.89 269.03 274.37 149.91 1.47 272.52-119.12 273.99-269.04 1.47-149.91-118.75-272.51-268.66-273.98zm240.31 786.06-496.01-4.88c-122.68-286.25-245.03-682.45-240.74-1119.2 0.69-70.33 2.85-139.17 6.47-206.5 10.57-209.06 34.33-405.03 68.25-579.42l865.43 8.49c40.3 231.77 62.09 500.37 59.27 787.24-4.29 436.79-134.39 830.51-262.67 1114.3"
        fill="var(--color_footer_bg)" />
      <path
        d="m2429.8 3035.6c1.1-112.37-89.02-204.27-201.4-205.38-112.37-1.1-204.55 89.01-205.65 201.39-1.1 112.37 89.28 204.56 201.66 205.66 112.37 1.1 204.28-89.29 205.39-201.67zm-212.86 966.66s-90.16-130.08-193.16-347.28l393.11 3.86c-107.25 215.14-199.95 343.42-199.95 343.42zm12.18-1240c-149.91-1.47-272.89 118.74-274.36 268.65-1.47 149.92 119.11 272.89 269.03 274.37 149.91 1.47 272.52-119.12 273.99-269.04 1.47-149.91-118.75-272.51-268.66-273.98zm240.31 786.06-496.01-4.88c-122.68-286.25-245.03-682.45-240.74-1119.2 0.69-70.33 2.85-139.17 6.47-206.5 10.57-209.06 34.33-405.03 68.25-579.42l865.43 8.49c40.3 231.77 62.09 500.37 59.27 787.24-4.29 436.79-134.39 830.51-262.67 1114.3z"
        fill="none" stroke="var(--color_footer_text)" stroke-miterlimit="10" stroke-width="26.71" />
      <path
        d="m2302.1 1729.6-75.27-265.91-68.8 267.6 7.3 621.83c0.47 39.79 33.09 71.66 72.88 71.19 39.78-0.46 71.66-33.1 71.19-72.88l-7.3-621.83"
        fill="var(--color_footer_text)" />
    </g>
  </g>
</svg>

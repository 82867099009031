import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { interval, Subscription } from 'rxjs';
import { GlobalService } from 'src/app/core/services/global.service';

@Component({
  selector: 'app-countdown-timer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnChanges, OnDestroy{
  
  @Input() endDate!: string; // Recibe la fecha final como parámetro en formato string.
  
  timeDifference: number = 0;

  timeLeft: any = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  private subscription!: Subscription;

  constructor(
    private _gs: GlobalService
  ) { }

  ngOnChanges(): void {
    this.startCountdown();
  }

  startCountdown() {

    // First revision
    const targetDate = new Date(this.endDate).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = targetDate - currentTime;
    this.timeDifference = timeDifference;
    this.timeLeft = this.calculateTimeLeft(timeDifference);

    this.ngOnDestroy();

    if(this._gs.isBrowser){
      this.subscription = interval(1000).subscribe(() => {
        const currentTime = new Date().getTime();
        const timeDifference = targetDate - currentTime;
        this.timeDifference = timeDifference;
  
        if (timeDifference <= 0) {
          this.timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
          this.subscription.unsubscribe(); // Finaliza el contador cuando se llega a la fecha final.
        } else {
          this.timeLeft = this.calculateTimeLeft(timeDifference);
        }
      });
    }

  }

  formatTimeUnit(unit: number): string {
    return unit < 10 ? '0' + unit : unit.toString();
  }

  calculateTimeLeft(timeDifference: number) {
    const days = this.formatTimeUnit(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
    const hours = this.formatTimeUnit(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutes = this.formatTimeUnit(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = this.formatTimeUnit(Math.floor((timeDifference % (1000 * 60)) / 1000));

    return { days, hours, minutes, seconds };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Asegura que se limpie la suscripción cuando el componente se destruya.
    }
  }

}

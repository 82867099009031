<div
  class="message"
  [class.secondary]="useSecondaryColor"
  [ngSwitch]="type"
>
  <a 
    class="message-text" 
    [href]="href" 
    target="_blank"
    [class.default]="!href"
    [class.animated]="type == 2"
    [ngStyle]="{'--animation-duration': animationDuration}"
  >

    <ng-container *ngSwitchCase="1">
      <span>{{ text }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>

      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>
      <span>{{ text }} </span>
    </ng-container>

    <ng-container *ngSwitchCase="3">
      <span *ngIf="text">{{ text }}</span>
      <app-countdown-timer
        [endDate]="extra"
      >
      </app-countdown-timer>
      <!-- Temporizador -->
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span>{{ text }}</span>
    </ng-container>

  </a>
</div>
<div class="countdown-timer" *ngIf="timeDifference > 0">
    <div class="time-unit">
      <span>{{ timeLeft.days }}</span>
    </div>
    <div class="separator">:</div>
    <div class="time-unit">
      <span>{{ timeLeft.hours }}</span>
    </div>
    <div class="separator">:</div>
    <div class="time-unit">
      <span>{{ timeLeft.minutes }}</span>
    </div>
    <div class="separator">:</div>
    <div class="time-unit">
      <span>{{ timeLeft.seconds }}</span>
    </div>
</div>
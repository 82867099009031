import { Component, OnInit, ViewEncapsulation } from '@angular/core'

// services
import { GlobalService } from '../../../core/services/global.service'
import { environment } from '../../../../environments/environment'
import { CommonModule } from '@angular/common';
import { SocialIconsComponent } from '../social-icons/social-icons.component';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { IconsModule } from '../../modules/icons/icons.module';
import { LazyImgDirective } from '../../directive/lazy-img.directive';
import { CDNImgDirective } from '../../directive/cdn-img.directive';
import { AnalyticsService } from '../../services/analytics.service';
import { LogoTnComponent } from "./components/logo-tn/logo-tn.component";

@Component({
  selector: 'app-footer',
  templateUrl: `./footer.component.html`,
  styleUrls: [`./footer.component.scss`],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    SocialIconsComponent,
    SafeHtmlPipe,
    IconsModule,
    LazyImgDirective,
    CDNImgDirective,
    LogoTnComponent
]
})

export class FooterComponent implements OnInit {

  menu = [];
  categories: any = [];
  env = environment;

  methodPayments = [];
  methodShipments = [];

  constructor(
    public globalService: GlobalService,
    private _analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.getCategories();
    this.getMethods();
  }

  click(source: string){ 
    this._analyticsService.trackEvent({ event: `${source}_button`, data: {} });
  }

  getCategories() {

    try{

      this.menu = this.globalService.menu;

      let categories = this.menu.find(menu=>menu.type_menu_item_id === 3);

      if(categories && categories.children){
        this.categories = categories;
      }

    }catch(err){
      console.log(err);
    }

  }

  getMethods(){
    let { methodsPayment, methodsShipment } = this.globalService.footer || {};
    this.methodPayments = methodsPayment;
    this.methodShipments = methodsShipment;
  }

}

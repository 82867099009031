import { sectionConfig } from './../app/features/home/views/home/data/section-container.47.data';

export const environment = {
  name: '(STAGING)',
  production: true,
  //baseUrl: "http://localhost:3000",
  baseUrl: 'https://apitest.tiendanegocio.com',
  galleryUrl: "https://cdn.v2.tiendanegocio.com/",
  assetsUrl: "https://cdn.v2.tiendanegocio.com/template_dev/",
  edgeUrl: "https://cdn.v2.tiendanegocio.com/template_dev/",
  template_id: 47,
  hostname: 'look.tiendanegocio.com',
  options: {
    withPreferencesForm: false,
    countRecommendProduct: 5,
    countColumnProduct: 4,
    countColumnProductSearch: 4,
    sectionsInfo: sectionConfig,
    imageSizeDefault: 'sm',
    iconFontWeight: 300
  }
};

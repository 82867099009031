import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownTimerComponent } from '../countdown-timer/countdown-timer.component';
import { GlobalService } from 'src/app/core/services/global.service';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
    CommonModule,
    CountdownTimerComponent
  ],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnChanges {

  @Input('href') href: string;
  @Input('text') text: string;
  @Input('type') type: 1 | 2 | 3 | 4; // 1: Estatico, 2: Dinamico, 3: Temporizador
  @Input('extra') extra: any;
  @Input('useSecondaryColor') useSecondaryColor: boolean = false;

  animationDuration: string = '0s';

  constructor(private _gs: GlobalService){ }

  ngOnChanges() {
    if(this.type == 2) {
      if(this._gs.isBrowser){
        this.adjustAnimationDuration();
      }
    }
  }

  private adjustAnimationDuration(): void {


    let duration = ((this.text.length * 16) / 10);

    if(duration <= 30){
      duration += 10;
    }

    if(duration >= 60 && duration <= 150){
      duration -= 10;
    }

    if(duration > 150){
      duration -= 20;
    }

    this.animationDuration = `${duration}s`

  }

}

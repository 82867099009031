import { Component, ViewContainerRef, ViewChild, AfterViewInit, ComponentRef, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageError } from './components/content/content.component';
import { GlobalService } from 'src/app/core/services/global.service';
import { CartService } from 'src/app/core/services/cart.service';
import { CommonModule } from '@angular/common';
import { KeysPipe } from 'src/app/core/pipes/keys.pipe';
import { IconsModule } from 'src/app/core/modules/icons/icons.module';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
import { DecimalsFormatDirective } from 'src/app/shared/directives/decimals-format.directive';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: `./templates/shopping-cart.${environment.template_id}.component.html`,
  styleUrls: [`./styles/shopping-cart.${environment.template_id}.component.scss`],
  standalone: true,
  imports: [
    CommonModule,
    KeysPipe,
    IconsModule,
    DecimalsFormatDirective
  ]
})
export class ShoppingCartComponent implements AfterViewInit{

  openMenu = false;
  activeTimeout = false;
  total;

  showListBox = {status: false, type: 1, alreadyAdd: false};
  shippingMethod;

  messageError: {
    status: boolean,
    message: string,
    products: any[]
  };

  whatsappMode: boolean = this.globalService.website.whatsapp_checkout_mode;
  @ViewChild('contentTemplate', {read: ViewContainerRef}) container: ViewContainerRef;
  contentRef: ComponentRef<any>;


  constructor(
    public globalService: GlobalService,
    public cartService: CartService,
    private _renderer: Renderer2,
    private _analyticsService: AnalyticsService
  ) {
  }

  ngAfterViewInit(): void {
    try {
      this.instanceContent();
    } catch (error) {
      console.error(error);
    }
  }

  async instanceContent(){

    let ContentComponent: any;

    if(this.whatsappMode){
      ContentComponent = await import("./components/content-ws/content-ws.component").then(m=>m.ContentWsComponent);
    }else{
      ContentComponent = await import("./components/content/content.component").then(m=>m.ContentComponent);
    }

    this.contentRef = (this.container.createComponent(ContentComponent) as ComponentRef<any>);

    //Ref all events
    this.contentRef.instance?.$onClose?.subscribe(($event: boolean)=>{
      this.closeCart();
    })

    this.contentRef.instance?.$onBuy?.subscribe(($event: boolean)=>{
      this.buy();
    })

  }

  clickCart(time?){
    this.activeTimeout = false;
    this.openMenu = true;
    this.updateView();
  }

  closeCart(){
    this.activeTimeout = false;
    this.openMenu = false;
    this.updateView();
    if(this.whatsappMode)
      this.contentRef.instance.reset();
  }

  modifyShipment(method){
    this.shippingMethod = method;
    this.updateView();
  }

  buy(){

    if(
      !this.globalService.options.checkout.only_buy_client ||
      this.globalService.isLogged
    ){
      this.checkCartStock();
    }else{
      this.globalService.requireSignin();
    }

  }

  applyMessageError(messageError: MessageError){
    this.contentRef.setInput('messageError', messageError);
  }

  checkCartStock(){

    this.applyMessageError(
      {
        status: false,
        message: null,
        products: []
      }
    )

    this.cartService.checkCartStock().subscribe((res:any)=>{

      if(res.status == 200){

        if(this.whatsappMode){
          window.location.href = res.data.url;
        }else{
          this.closeCart();
          this.globalService.navigate(['compra', 'proceso', 'contacto']);
        }

      }else{

        this.applyMessageError({
          status: true,
          message: res.error.message,
          products: res.error.products,
        });

      }

    }, (error) =>{
      
      this.applyMessageError({
        status: true,
        message: 'Hubo un error al cargar tu carrito de compra, por favor, intentalo de nuevo.',
        products: []
      });

    }, () =>{

    });

  }

  updateView(){
    if(this.globalService.isBrowser){
      if(this.openMenu){
        this._renderer.setStyle(document.body, 'overflow', 'hidden');
      }else{
        this._renderer.removeStyle(document.body, 'overflow');
      }
    }
  }

}

<!-- footer for non-payment views --------------------------------------------->
<ng-container
  *ngIf="globalService.page != 'purchase-steps'; else purchaseFooter"
>
  <footer class="footer">
    <div class="logo container">
      <span class="title">
        {{ globalService.website.title }}
      </span>
    </div>
    <div class="container">
      <div class="column column--left">
        <div class="list">


          <div class="list__header" *ngIf="methodPayments?.length">
            <p>Método de pago</p>
          </div>
          <div class="list__content">
            <img class="logo_entity" [src]="payment.url" [id]="payment.id" [alt]="payment.id" height="31.5" width="60"  *ngFor="let payment of methodPayments">
          </div>

          <div class="list__header" *ngIf="methodShipments?.length">
            <p>Método de envío</p>
          </div>
          <div class="list__content">
            <img class="logo_entity" [src]="shipment.url" [id]="shipment.id" [alt]="shipment.id" height="31.5" width="60" *ngFor="let shipment of methodShipments">
          </div>

        </div>
      </div>
      <div class="column column--right">

        <div class="list">
          <div class="list__header">
            <p>Contactanos</p>
          </div>
          <div class="list--contact">
            <div>
              <ul>
               <li class="event" *ngIf="globalService.website.direction">
                  <a>
                    <app-icon fill="1" icon="home" class="icon">
                    </app-icon>
                    {{ globalService.website.direction }}
                  </a>
                </li>
                <li class="event numbers" *ngIf="globalService.website.phone">
                  <a (click)="click('call')" [href]="'tel:'+globalService.website.phone">
                    <app-icon fill="1" icon="phone" class="icon">
                    </app-icon>
                    {{ globalService.website.phone }}
                  </a>
                </li>
                <li class="event" *ngIf="globalService.website.email">
                  <a (click)="click('mail')" [href]="'mailto:'+globalService.website.email">
                    <app-icon fill="1" icon="mail" class="icon">
                    </app-icon>
                    {{ globalService.website.email }}
                  </a>
                </li>
                <li class="event">
                  <a (click)="globalService.regretBox = true">
                    <app-icon fill="1" icon="undo" class="icon">
                    </app-icon>
                    Botón de arrepentimiento
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="copyright">
            <div
              class="afip"
              *ngIf="globalService.options.datafiscal.status"
              [innerHTML]="globalService.options.datafiscal.data.datafiscal_codigo | safeHtml"
            >
            </div>
          </div>
        </div>

        <div class="list">
          <ng-container
            *ngIf="
              globalService.social.facebook ||
              globalService.social.twitter ||
              globalService.social.pinterest ||
              globalService.social.instagram ||
              globalService.social.youtube ||
              globalService.social.tiktok
            "
          >
            <div class="list__header">
              <p>Redes sociales</p>
            </div>
            <div class="list__content">
              <app-social-icons
                class="social-icons"
                [facebook]="globalService.social.facebook"
                [twitter]="globalService.social.twitter"
                [pinterest]="globalService.social.pinterest"
                [instagram]="globalService.social.instagram"
                [youtube]="globalService.social.youtube"
                [tiktok]="globalService.social.tiktok"
              >
              </app-social-icons>
            </div>
          </ng-container>

          <div class="creator">
            <div class="list__header">
              <p class="creator_title">Creado con</p>
            </div>
            <div class="list__content">
              <a
                class="creator_logo"
                href="https://tiendanegocio.com"
                target="_blank"
              >
                <app-logo-tn></app-logo-tn>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</ng-container>
<!-- end footer for non-payment views ----------------------------------------->

<!-- header for payment views ------------------------------------------------->
<ng-template #purchaseFooter>
  <!-- <footer>
    <div class="container">
      Medios de pago
    </div>
  </footer> -->
</ng-template>
<!-- end header for payment views --------------------------------------------->
